<script lang="ts" setup>
import OverlayBase from '@/components/overlays/OverlayBase.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import TwoColsBlock from '@/components/layout/TwoColsBlock.vue'
import SkrHeading from '@/components/SkrHeading.vue'
import SkrButton from '@/components/button/SkrButton.vue'
import SkrButtonGroup from '@/components/button/SkrButtonGroup.vue'

const props = defineProps<{
  isLinkFlow: boolean
  documentTitle: string
  requestOwnerName: string
  isLoading: boolean
}>()

const emit = defineEmits<{
  (e: 'action', type: OverlayAction, data?: unknown): void
  (e: 'close'): void
}>()

const { t } = useI18n()

const info = computed<{ title: string; subtitle: string; positiveAction: string; negativeAction: string }>(() => {
  if (props.isLinkFlow) {
    return {
      title: t('viewer.tan.invalid.link_flow.title'),
      subtitle: t('viewer.tan.invalid.link_flow.text'),
      positiveAction: t('viewer.tan.invalid.link_flow.cta'),
      negativeAction: t('viewer.tan.invalid.link_flow.cta_back'),
    }
  } else {
    return {
      title: t('viewer.tan.invalid.tan_flow.title'),
      subtitle: t('viewer.tan.invalid.tan_flow.text'),
      positiveAction: t('viewer.tan.invalid.tan_flow.cta'),
      negativeAction: t('viewer.tan.invalid.tan_flow.cta_back'),
    }
  }
})

const sendTan = () => {
  emit('action', 'retry')
}
</script>

<template>
  <overlay-base :transition="false">
    <two-cols-block split="5/7" class="fill-height">
      <template #left>
        <div class="mb-8">
          <skr-heading level="1" semantic>{{ info.title }}</skr-heading>
          <skr-heading level="2">{{ info.subtitle }}</skr-heading>
        </div>
        <p v-if="documentTitle" class="mb-2">
          <span>{{ t('viewer.tan.document_title') }}</span>
          {{ ' ' }}
          <strong>{{ documentTitle }}</strong>
        </p>
        <p v-if="requestOwnerName" class="mb-8">
          <span>{{ t('viewer.tan.invited_by_title') }}</span>
          {{ ' ' }}
          <strong class="text-truncate">{{ requestOwnerName }}</strong>
        </p>
        <skr-button-group size="lg" stacked>
          <skr-button :loading="isLoading" @click="sendTan">{{ info.positiveAction }}</skr-button>
          <skr-button type="secondary" @click="emit('close')">{{ info.negativeAction }}</skr-button>
        </skr-button-group>
      </template>
      <template #right>
        <responsive-image source="verification-code-wrong" :width="560" />
      </template>
    </two-cols-block>
  </overlay-base>
</template>
